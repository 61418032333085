<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("user.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
          :placeholder="$t('user.search')">
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("user.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
            <tr>
              <th class="text-start">
                {{ $t("user.id") }}
              </th>
              <th class="text-start">
                {{ $t("user.image") }}
              </th>
              <th class="text-left">
                {{ $t("user.name") }}
              </th>
              <th class="text-left">
                {{ $t("user.email") }}
              </th>
              <th class="text-left">
                {{ $t("user.role") }}
              </th>
              <th class="text-left">

              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in allUserCompany" :key="idx">
              <td>{{ item.userCardId }}</td>
              <td class="text-start">
                <div class="img-table-company" v-if="item.urlProfile">
                  <img :src="item.urlProfile" alt="">
                </div>
                <div class="demo-profile" v-else>
                  <i class="fal fa-user-alt"></i>
                </div>
              </td>
              <td class="employee-name">
                {{ item.userName }}
              </td>
              <td>
                {{ item.userEmail }}
              </td>

              <!--            <td v-for="(RoleUser,idx) in item.roleUser" :key="idx">-->
              <!--              &lt;!&ndash;              <div class="companyAdmin" v-if="RoleUser === 'companyadmin'">&ndash;&gt;-->
              <!--              &lt;!&ndash;                <span>Company Admin</span>&ndash;&gt;-->
              <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
              <!--              &lt;!&ndash;              <div class="employee" v-if="RoleUser === 'employee'">&ndash;&gt;-->
              <!--              &lt;!&ndash;                <span>Employee</span>&ndash;&gt;-->
              <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
              <!--              &lt;!&ndash;              <div class="companyUser" v-if="RoleUser === 'companyuser'">&ndash;&gt;-->
              <!--              &lt;!&ndash;                <span>CompanyUser</span>&ndash;&gt;-->
              <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
              <!--              <div>-->
              <!--                <div class="d-flex flex-column">-->
              <!--                  {{ RoleUser }}-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </td>-->
              <td>
                <div v-for="(RoleUser, idx) in item.roleUsers" :key="idx">
                  <v-chip small>
                    {{ RoleUser.name }}
                  </v-chip>
                </div>
              </td>


              <td class="text-right">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.companyUserId)">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onDel(item.userId)">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <ModalEdit fullscreen title="EditEmployee">
          <template v-slot="{ close }">
            <edit-company-user :companyUser="companyUser" @close="close" @success="fetchAllCompanyUser" />
          </template>
        </ModalEdit>
        <deleteCompanyUser :dialog="deleteUserDialog" @close="(val) => deleteUserDialog = val" @confirm="delUser" />
      </div>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import editCompanyUser from "@/components/companyUser/from/editCompanyUser";
import deleteCompanyUser from "@/components/companyUser/from/deleteCompanyUser.vue";
import Loading from "@/components/Loading";

export default {
  components: {
    editCompanyUser,
    deleteCompanyUser,
    Loading,
  },
  data() {
    return {
      deleteUserDialog: false,
      allUserCompany: [],
      companyUser: {},
      isLoading: false,
    }
  },
  methods: {


    filterCompanyUser(companyUserId) {
      return (
        this.allUserCompany.filter((item) => {
          return item.companyUserId == companyUserId;
        })[0] || {}
      )
    },


    onCreate() {
      this.$router.push({ name: 'companyUser.create' }).catch(() => {
      });
    },
    onDel(companyUserId) {
      this.companyUserId = companyUserId;
      this.deleteUserDialog = true;
    },
    delUser() {
      this.isLoading = true;

      this.$axios.delete(`/company/delete-user/${this.companyUserId}`).then((res) => {
        this.fetchAllCompanyUser();
      }).catch(() => {

      })
    },

    onEdit(companyUserId) {
      this.companyUser = {
        ...this.filterCompanyUser(companyUserId)
      };
      this.$store.commit("modalEdit_State", true);
    },

    fetchAllCompanyUser() {
      this.isLoading = true;
      this.$axios.get(`company/list-user`).then((res) => {
        this.allUserCompany = res.data.data.data;
        this.isLoading = false;
      }).catch(() => {

        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchAllCompanyUser();
  }
}
</script>

<style scoped lang="scss">
.employee {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #D25B55;
  font-size: 12px;
  letter-spacing: 1px;

  span {
    background-color: #FFE4E4;
    padding: 10px 28px;
    border-radius: 40px;
  }
}

.companyAdmin {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 1px;

  span {
    background-color: #E9F9EA;
    padding: 10px 10px;
    border-radius: 40px;
    color: #6CB669;
  }
}

.companyManager {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 13px;
  color: #FFF6E7;

  span {
    background-color: #FFF6E7;
    padding: 4px 10px;
    border-radius: 40px;
    color: #CB9247;
  }
}


.companyUser {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 13px;
  color: #C5E6F8;

  span {
    background-color: #C5E6F8;
    padding: 10px 20px;
    border-radius: 40px;
    color: #2380B5;
  }
}
</style>
