<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card class="lfont">
                <v-card-title class="text-h5">
                    <span class="lfont">ລືບຜູ້ໃຊ້ລະບົບ</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <p>Typing <span class="text-danger">confirm</span> to confirm delete.</p>
                        <v-text-field :rules="nameRules" v-model="confirm" required ref="confirmInput"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="confirmDelete">
                        OK
                    </v-btn>
                    <v-btn color="green darken-1" text @click="$emit('close', false)">
                        cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        dialog: ""
    },
    data() {
        return {
            valid: true,
            confirm: "",
            confirmRule: "confirm",
            nameRules: [
                v => !!v || 'required !!',
                v => v === 'confirm' || 'Typing Incorrect'
            ],
            companyUserId: ""
        }
    },
    methods: {
        confirmDelete() {
            this.$refs.form.validate();
            if (this.confirm == this.confirmRule) {
                this.$emit('confirm', true);
                this.$emit('close', false);
                this.confirm = '';
            }
            this.$refs.confirmInput.focus();

        }
    },
}
</script>