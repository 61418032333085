<template>
  <v-container fluid>
    <div class="from-content">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <div class="image-emp-profile">
              <div class="image-container">
                <div class="image-company">
                  <img :src="showImage" alt="" />
                  <div class="image-button">
                    <div class="btn-img">
                      <i class="fas fa-camera"></i>
                      <input
                        @change="PreviewImage"
                        class="file-input"
                        type="file"
                        name="resume"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              :label="$t('user.name')"
              v-model="data.name"
              :hint="`${server_errors.name}`"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              :label="$t('user.email')"
              v-model="data.email"
              type="email"
              :hint="`${server_errors.email}`"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              dense
              :items="companyUser"
              item-text="name"
              item-value="id"
              :label="$t('user.role')"
              v-model="data.roleId"
              :hint="`${server_errors.roleId}`"
              persistent-hint
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <!-- <v-col cols="12" md="6">
            <v-select
              outlined
              dense
              :items="listPermission"
              item-text="display_name"
              item-value="id"
              :label="$t('permission.name')"
              v-model="allPermission"
              :hint="`${server_errors.permissionId}`"
              persistent-hint
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        selectAllPermission.length > 0 ? 'indigo darken-4' : ''
                      "
                    >
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col> -->

          <v-col cols="12">
            <p class="ml-12 lfont">{{ $t("permission.name") }}</p>
            <v-row class="ml-8">
              <v-col
                cols="12"
                md="6"
                lg="6"
                v-for="(item, idx) in listPermission"
                :key="idx"
              >
                <v-checkbox
                  v-model="allPermission"
                  color="primary lfont"
                  :value="item.id"
                  hide-details
                >
                  <template v-slot:label>
                    <div style="color:blue !important;">
                      {{ $t(show_permission(item.name)) }}
                    </div>
                  </template>
                </v-checkbox>
                <div>
                  <ul>
                    <li
                      class="depend_permission"
                      v-for="(depend_item, index) in item.permission_depend"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="permissionUsers1"
                        :label="$t(show_permission_depend(depend_item.name))"
                        :value="depend_item.id"
                      >
                      </v-checkbox>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="6" md="6" class="mt-6 mb-6">
            <v-btn
              class="btn-save-change"
              @click="ValidateForm"
              :loading="btnLoading"
            >
              {{ $t("user.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "createCompanyUser",
  data() {
    return {
      showPassword: false,
      btnLoading: false,
      showImage: "",
      listRoleUser: [],
      data: {
        name: "",
        email: "",
        // password: "",
        roleId: "",
        profile: "",
        permissionId: "",
      },
      companyUser: [],
      permissionUsers1: [],
      listPermission: [],
      allPermission: [],
      server_errors: {
        name: "",
        email: "",
        password: "",
        roleId: "",
        permissionId: "",
      },
    };
  },

  computed: {
    selectAllPermission() {
      return this.allPermission.length === this.listPermission.length;
    },
    selectSomePermission() {
      return this.allPermission.length > 0 && !this.selectAllPermission;
    },
    icon() {
      if (this.selectAllPermission) return "mdi-close-box";
      if (this.selectSomePermission) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    show_permission(permission_name) {
      if (permission_name == "managegeneral") {
        return "Navbar.ManageComdata";
      } else if (permission_name == "manageemployee") {
        return "Navbar.ManageEmployee";
      } else if (permission_name == "managesalary") {
        return "Navbar.ManageSalary";
      } else if (permission_name == "manageactivity") {
        return "Navbar.activity";
      } else if (permission_name == "managereport") {
        return "Navbar.report";
      } else if (permission_name == "managezone") {
        return "Navbar.zone";
      } else if (permission_name == "managecompanyuser") {
        return "Navbar.user";
      } else if (permission_name == "managedocument") {
        return "Navbar.authorizeDocument";
      } else if (permission_name == "managedocument-in-out") {
        return "Navbar.document";
      } else if (permission_name == "managecompanylog") {
        return "Navbar.activity log";
      } else if (permission_name == "manageschedule") {
        return "Navbar.schedule";
      }
    },
    show_permission_depend(permission_name) {
      if (permission_name == "managebranch") {
        return "Navbar.branch";
      } else if (permission_name == "managedepartment") {
        return "Navbar.department";
      } else if (permission_name == "manageposition") {
        return "Navbar.position";
      } else if (permission_name == "manageemployeelevel") {
        return "Navbar.employeeLevel";
      } else if (permission_name == "managebenefit") {
        return "Navbar.benefit";
      } else if (permission_name == "managegeneral-setting_ot") {
        return "Navbar.ot";
      } else if (permission_name == "managelate") {
        return "Navbar.late";
      } else if (permission_name == "manageabsence") {
        return "Navbar.absence";
      } else if (permission_name == "managededuction") {
        return "Navbar.deduction";
      } else if (permission_name == "manageholiday") {
        return "Navbar.rest day";
      } else if (permission_name == "managevacation") {
        return "Navbar.vacation";
      } else if (permission_name == "manageemployee-employee") {
        return "Navbar.employee";
      } else if (permission_name == "manageemployee-holiday") {
        return "Navbar.EmpHoliday";
      } else if (permission_name == "manageemployee-scan_point") {
        return "Navbar.scan_point";
      } else if (permission_name == "manageemployee-scan_time") {
        return "Navbar.scan_time";
      } else if (permission_name == "manageemployee-scan_camera") {
        return "Navbar.scan_camera";
      } else if (permission_name == "manageemployee-verify_code") {
        return "Navbar.verify_code";
      } else if (permission_name == "managesalary_calculate") {
        return "Navbar.salary";
      } else if (permission_name == "manageactivity-anouncement") {
        return "activity.anouncement.navbar";
      } else if (permission_name == "manageactivity-metting") {
        return "activity.metting.navbar";
      } else if (permission_name == "managereport-devicescan") {
        return "report.deviceScan";
      } else if (permission_name == "managereport-absence") {
        return "report.absenceReport";
      } else if (permission_name == "managereport-sso") {
        return "report.sso";
      } else if (permission_name == "managereport-tax") {
        return "report.emTaxeport";
      } else if (permission_name == "managereport-todolist") {
        return "report.todolist";
      } else if (permission_name == "managereport-e_approval") {
        return "report.e_approval";
      } else if (permission_name == "managereport-e_leave") {
        return "report.e_leave";
      } else if (permission_name == "managereport-follow_schedule") {
        return "follow_schedule.nav";
      } else if (permission_name == "manageschedule-schedule") {
        return "groupSchedule.schedule.navbar";
      } else if (permission_name == "manageschedule-roster") {
        return "groupSchedule.roster.navbar";
      } else if (permission_name == "manageschedule-add_ot") {
        return "employee_ot.navbar";
      } else if (permission_name == "manageschedule-info_ot") {
        return "info_ot.navbar";
      } else if (permission_name == "approvesalary") {
        return "payrollItem.approve";
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllPermission) {
          this.allPermission = [];
        } else {
          this.allPermission = this.listPermission.slice();
        }
      });
    },

    PreviewImage: function(event) {
      const input = event.target;

      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpg,jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.data.profile = res.data.filename;
        }
      });
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    getRoleUser() {
      this.$axios
        .get(`role`)
        .then((res) => {
          this.listRoleUser = res.data.data;
          this.companyUser = this.listRoleUser.filter((item) => {
            if (
              item.name == "companymanager" ||
              item.name == "companyadmin" ||
              item.name == "companyuser"
            ) {
              return item.id;
            }
          });
        })
        .catch(() => {});
    },
    getPermission() {
      this.$axios
        .get(`list/permissions`)
        .then((res) => {
          console.log(res);
          this.listPermission = res.data.data;
        })
        .catch(() => {});
    },

    searchFilterItem() {
      this.getRoleUser();
    },
    saveChange() {
      const formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("email", this.data.email);
      formData.append("roleId", this.data.roleId);
      if (this.allPermission) {
        this.allPermission.map((item) => {
          formData.append("permissionId[]", item.id || item);
        });
      }
      if (this.permissionUsers1) {
        this.permissionUsers1.map((item) => {
          formData.append("permissionId[]", item);
        });
      }
      if (this.imageFile) {
        formData.append("profile", this.data.profile);
      }

      this.$axios
        .post(`company/add-user`, formData)
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.$router.push({ name: "companyUser.index" });
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
        });
    },
  },
  created() {
    this.getRoleUser();
    this.getPermission();
    //this.filterCompanyUser();
  },
};
</script>

<style scoped lang="scss">
.v-input--selection-controls {
  font-family: $font-family !important;
  color: red !important;
}
.depend_permission {
  font-size: 12px !important;
  color: red !important;
  list-style-type: none;
}
.image-emp-profile {
  width: 100%;
  height: 180px;
  margin-top: 10px;
}

.from-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 400px;
    height: auto;
    margin-bottom: 80px;
  }
}
.label_permission label {
  color: red !important;
}
</style>
